import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GlobalLayout from "../layouts/globalLayout";
import SectionHeader from "../components/SectionHeader/sectionHeader";
import PictureCardWithBorder from "../components/PictureCardWithBorder/pictureCardWithBorder";
import Carousel from "../components/Carousel/carousel";
import styles from "./index.module.css";
import useClientServerWorkaround from "../components/helperHooks";
import ContactUsForm from "../components/ContactUsForm/contactUsForm";

const imagesQuery = graphql`
  query {
    cosmeticPic: file(
      relativePath: { eq: "websitelogosandcolorscheme/cosmetic-services-gold-icons-transparent-cropped.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    estheticianPic: file(
      relativePath: { eq: "websitelogosandcolorscheme/esthetician-services-gold-icons-transparent-cropped.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    skinCarePic: file(relativePath: { eq: "websitelogosandcolorscheme/skincare-gold-icons-transparent-cropped.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sliderPic1: file(relativePath: { eq: "homePage/DiamondGlow_WEB_BANNER.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sliderPic2: file(relativePath: { eq: "websitelogosandcolorscheme/banner-1-blue-watercolor.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sliderPic3: file(relativePath: { eq: "homePage/diamondGlowBanner.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sliderPic4: file(relativePath: { eq: "homePage/patientFi.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aptBkgrnd: file(relativePath: { eq: "websitelogosandcolorscheme/20.png" }) {
      childImageSharp {
        fixed(cropFocus: CENTER, height: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ringBkgrnd: file(relativePath: { eq: "websitelogosandcolorscheme/17.png" }) {
      childImageSharp {
        fixed(cropFocus: CENTER, height: 550) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    beforeAfter1: file(relativePath: { eq: "homePage/beforeAfter1.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter2: file(relativePath: { eq: "homePage/beforeAfter2.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter3: file(relativePath: { eq: "homePage/beforeAfter3.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter4: file(relativePath: { eq: "homePage/beforeAfter4.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter5: file(relativePath: { eq: "homePage/beforeAfter5.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter6: file(relativePath: { eq: "homePage/beforeAfter6.jpeg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter7: file(relativePath: { eq: "homePage/beforeAfter7.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter8: file(relativePath: { eq: "homePage/beforeAfter8.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter9: file(relativePath: { eq: "homePage/beforeAfter9.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter10: file(relativePath: { eq: "homePage/beforeAfter10.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter11: file(relativePath: { eq: "homePage/beforeAfter11.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter12: file(relativePath: { eq: "homePage/beforeAfter12.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter13: file(relativePath: { eq: "homePage/beforeAfter13.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfter14: file(relativePath: { eq: "homePage/beforeAfter14.JPG" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function Home() {
  const homePageQuery = useStaticQuery(imagesQuery);
  const { 
    skinCarePic, 
    estheticianPic, 
    cosmeticPic, 
    aptBkgrnd, 
    ringBkgrnd, 
    beforeAfter1, 
    beforeAfter2,
    beforeAfter3,
    beforeAfter4,
    beforeAfter5,
    beforeAfter6,
    beforeAfter7,
    beforeAfter8,
    beforeAfter9,
    beforeAfter10,
    beforeAfter11,
    beforeAfter12,
    beforeAfter13,
    beforeAfter14,
  } = homePageQuery;
  const largeScreen = useMediaQuery("(min-width:600px)");
  const key = useClientServerWorkaround();

  const carouselChildren = useMemo(() => {
    return [
      <>
        {/* <div className={styles.carousel__text__header}>Don't let aging get you down</div>
        <div className={styles.carousel__text__description}>
          With our top of the line products and treatments this won't be a problem
        </div> */}
      </>,
      <>
        {/* <div className={styles.carousel__text__header}>Don't let aging get you down</div>
        <div className={styles.carousel__text__description}>
          With our top of the line products and treatments this won't be a problem
        </div> */}
      </>,
    ];
  }, []);

  return (
    <GlobalLayout key={key}>
      <Carousel
        pictures={[homePageQuery.sliderPic1.childImageSharp.fluid, homePageQuery.sliderPic2.childImageSharp.fluid]}
        pictureChildren={carouselChildren}
      />
      <div className={styles.headerContainer}>
        <SectionHeader 
          title="Love Your Skin." 
          extraTitles={["Natural Looking Enhancements,", "Beautiful Results."]} 
          className={styles.headerText}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "100px",
          marginBottom: "100px",
          flexWrap: "wrap",
        }}
      >
        <PictureCardWithBorder fixed={cosmeticPic.childImageSharp.fixed} text="Cosmetic Services" href="/bookonline" />
        <PictureCardWithBorder fixed={estheticianPic.childImageSharp.fixed} text="Esthetician Services" href="/contact?contactToBookEsthetician=true" />
        <PictureCardWithBorder fixed={skinCarePic.childImageSharp.fixed} text="Skincare" buttonText="Shop In Store"/>
      </div>

      <div className={styles.section__container__striped} key={key}>
        <Img
          style={
            largeScreen
              ? {
                  overflow: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "640px",
                  minWidth: "640px",
                }
              : {
                  overflow: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "500px",
                  minWidth: "500px",
                }
          }
          fixed={ringBkgrnd.childImageSharp.fixed}
          imgStyle={{ objectFit: "contain" }}
        />
        <Img
          style={
            largeScreen
              ? {
                  overflow: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  minWidth: "500px",
                }
              : {
                  overflow: "hidden",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  minWidth: "350px",
                }
          }
          fixed={aptBkgrnd.childImageSharp.fixed}
          imgStyle={{ objectFit: "contain" }}
        />
        <a href="/bookonline" className={styles.section__container__innerbox}>
          <SectionHeader className={styles.section__container__innerbox__header} title="Book An Appointment" light />
          <div className={styles.section__container__innerbox__description}>
            <p>Find a time that works for you by calling us at (909) 795-2050</p>
            <p>Or send us an email at info@skinsolutionsaesthetics.com</p>
          </div>
        </a>
      </div>
      <div style={{position: 'relative', display: 'block'}}>
        <ContactUsForm />
      </div>
      <div className={styles.beforeAfterContainer}>
          <SectionHeader 
            title="Before and After Gallery" 
            className={styles.headerText}
          />
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter1.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter2.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter3.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter4.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter5.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter6.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter7.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter8.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter9.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter10.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter11.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter12.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
          <div className={styles.beforeAfterContainerRow}>
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter13.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
            <Img
              className={styles.beforeAfterPic}
              fluid={beforeAfter14.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
      </div>
    </GlobalLayout>
  );
}
