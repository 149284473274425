import React, { useState } from "react";
import Img from "gatsby-image";
import clsx from "clsx";
import { navigate } from "@reach/router";
import styles from "./pictureCardWithBorder.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    width: "250px",
    height: "500px",
    maxHeight: 300,
    border: "15px solid #8f8f8f",
    margin: "12px 12px",
  },
  text: {
    display: "flex",
    width: "100%",
    height: "15%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#8f8f8f",
    fontSize: 18,
    fontFamily: "'Raleway', sans-serif",
  },
  media: {
    width: "100%",
    height: "60%",
    display: "flex",
  },
  actionArea: {
    width: "100%",
    height: "100%",
  },
});

export default function PictureCardWithBorder(props) {
  const { fixed, text, buttonText, href } = props;
  const classes = useStyles();
  const [hovering, setIsHovering] = useState(false);

  return (
    <Card className={classes.root} raised={hovering}>
      <CardActionArea
        className={clsx(classes.actionArea, { [classes.hover]: hovering })}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        href={href}
      >
        <CardMedia className={classes.media}>
          <Img
            style={{
              overflow: "visible",
              width: "200px",
              margin: "auto",
            }}
            fixed={fixed}
            imgStyle={{ objectFit: "contain" }}
          />
        </CardMedia>
        <CardContent>{!!text && <div className={clsx(classes.text, styles.text)}>{text}</div>}</CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <div
            style={{
              backgroundColor: "#FCD2C0",
              color: "white",
              fontFamily: "'Raleway', sans-serif",
              fontWeight: 600,
              padding: '8px 20px',
              borderRadius: '4px'
            }}
          >
            {buttonText ? buttonText : 'Book Now'}
          </div>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
